import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import * as React from "react"
import Layout from "../components/Layout"
import "react-tabs/style/react-tabs.css"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import InformationBox from "../components/InformationBox"
const shortcodes = { InformationBox }

const UsagePageTemplate = ({ data, pageContext }) => {
  const usageDescription = data?.description?.body
  const sampleRequest = data?.sampleRequest?.body
  const sampleResponse = data?.sampleResponse?.body
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) => {
    crumb.crumbLabel = crumb?.crumbLabel?.replaceAll("-", " ")
  })
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      {/* <section className="usage-page">
        <div className="d-flex flex-wrap">
          <div className="half-column"> */}
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{usageDescription}</MDXRenderer>
      </MDXProvider>

      {/* </div> */}
      {/* <div className="half-column">
            <h2>Sample </h2>
            <Tabs>
              <TabList>
                <Tab>Request</Tab>
                <Tab>Response</Tab>
              </TabList>
              <TabPanel>
                <MDXProvider>
                  {sampleRequest && <MDXRenderer>{sampleRequest}</MDXRenderer>}
                </MDXProvider>
              </TabPanel>
              <TabPanel>
                <MDXProvider>
                  {sampleResponse && (
                    <MDXRenderer>{sampleResponse}</MDXRenderer>
                  )}
                </MDXProvider>
              </TabPanel>
            </Tabs>
          </div> */}
      {/* </div>
      </section> */}
    </Layout>
  )
}
export default UsagePageTemplate

export const query = graphql`
  query usage(
    $slug: String
    $sampleRequestSlug: String
    $sampleResponseSlug: String
  ) {
    description: mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date
      }
      slug
      body
    }
    sampleRequest: mdx(slug: { eq: $sampleRequestSlug }) {
      frontmatter {
        title
        date
      }
      slug
      body
    }
    sampleResponse: mdx(slug: { eq: $sampleResponseSlug }) {
      frontmatter {
        title
        date
      }
      slug
      body
    }
  }
`
